import { EditorSDK } from '@wix/platform-editor-sdk';

import * as experiments from '../../utils/experiments';
import {
  getGlobalSettings,
  mergeGlobalSettings,
  setAboutPageBooleanParam,
  setMyAccountBooleanParam,
  setProfileCardBooleanParam,
} from './members-area';

export const getCustomProfileBooleanSetting = (isEnabled: boolean) => ({
  key: 'customProfileEnabled',
  value: isEnabled,
});

export const maybeSetCustomProfileParams = async (editorSDK: EditorSDK) => {
  const isCustomProfileEnabled = await experiments.isCustomProfileEnabled();

  if (!isCustomProfileEnabled) {
    return;
  }

  const isCustomProfileCurrentlyEnabled = await getGlobalSettings(editorSDK)
    .then((response) => response?.data?.customProfileEnabled)
    .catch(() => true);

  if (isCustomProfileCurrentlyEnabled) {
    return;
  }

  const settingsParam = getCustomProfileBooleanSetting(isCustomProfileEnabled);

  await setMyAccountBooleanParam(editorSDK, settingsParam);
  await setAboutPageBooleanParam(editorSDK, settingsParam);
  await setProfileCardBooleanParam(editorSDK, settingsParam);
  await mergeGlobalSettings(editorSDK, { customProfileEnabled: isCustomProfileEnabled });
};
