import Experiments from '@wix/wix-experiments';

let conductedExperiments: Experiments;

async function maybeConductExperiments() {
  if (!conductedExperiments) {
    conductedExperiments = new Experiments({ scope: 'members-area' });
  }
  await conductedExperiments.ready();
}

async function areAppWidgetsEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.AppWidgets') === 'true';
}

async function isADIHorizontalLayoutEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.ADIHorizontalLayout') === 'true';
}

async function shouldDisableBrokenMADeletion() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.disableAutoRemoval') === 'true';
}

async function isAddMinHeightEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.AddMinHeight') === 'true';
}

async function isAddUninstallMAActionEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.AddUninstallMAAction') === 'true';
}

async function areTransactionsEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.EnableEditorTransactions') === 'true';
}

async function isCustomProfileEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.customProfile') === 'true';
}

async function shouldSetResponsiveLayoutForApps() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.setResponsiveLayoutForApps') === 'true';
}

async function shouldUseNewStyleModals() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.NewStyleSantaModals') === 'true';
}

async function shouldRevertOnInstallationErrors() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.RevertOnInstallationErrors') === 'true';
}

async function extraAutopilotLogsEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.autopilotLogs') === 'true';
}

export {
  areAppWidgetsEnabled,
  maybeConductExperiments,
  isADIHorizontalLayoutEnabled,
  shouldDisableBrokenMADeletion,
  isAddMinHeightEnabled,
  isAddUninstallMAActionEnabled,
  areTransactionsEnabled,
  isCustomProfileEnabled,
  shouldSetResponsiveLayoutForApps,
  shouldUseNewStyleModals,
  shouldRevertOnInstallationErrors,
  extraAutopilotLogsEnabled,
};
